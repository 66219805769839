import './App.css';

function App() {
  return (
    <>
      <div className="container">
        <canvas id="drawingCanvas"></canvas>
        <div style={{marginTop: '-300px', zIndex: 99}}>
          <h1 style={{zIndex: 99}}>$POWERPUFF</h1>
          <h2 style={{zIndex: 99}}>CA: 8aFbCkUT32xx1id2nDfUFpzHgqFntCPVQ2e1zhR3pump</h2>
        </div>
      </div>

      <div className="blank">
        <div className="conveyor">
          <div className="conveyor-inner">
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
          </div>
        </div>
      </div>

      <div className="container second">
        <h2 style={{zIndex: 99}}>Not all superheroes wear tights.</h2>
        <br />
        <h2 style={{zIndex: 99}}>Some wear pretty dresses! </h2>
      </div>

      <div className="blank second-blank">
        <div className="conveyor">
          <div className="conveyor-inner">
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
            <span>$POWERPUFF</span>
          </div>
        </div>
      </div>

      <div className="container third">
        <h2 style={{zIndex: 99}}>Saving the world before bedtime!</h2>
        <br />
        <h2 style={{zIndex: 99}}>$POWERPUFF</h2>
      </div>

      <nav>
        <ul className="list">
          <li>
            <a href="https://x.com/powerpuff_sol">
              <img src="X.png" className="logo-img" alt="Logo" />
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default App;
